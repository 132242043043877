var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"container",attrs:{"id":"MyBusinessGeneralInfo","loading":_vm.isLoading,"outlined":""}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"primary","height":"10","indeterminate":""}})],1),_c('div',{staticClass:"header"},[_c('h2',{staticClass:"header-title"},[_vm._v("Informations générales sur l'activité LMNP")]),_c('div',[(!_vm.isEditing)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"header-action",attrs:{"id":"track-modify_lmnp_info","color":"primary","icon":""},on:{"click":function($event){_vm.isEditing = !_vm.isEditing}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,false,809525330)},[_c('span',[_vm._v("Modifier")])]):_vm._e()],1)]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.updateProduct($event)}}},[(_vm.currentActivity)?_c('v-card-text',{staticClass:"section"},[_c('p',[_vm._v(" Tous les champs marqués d'un "),_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" sont obligatoires. ")]),_c('div',{staticClass:"section-infos"},[_c('div',{staticClass:"content-x2"},[_c('v-text-field',{staticClass:"position-left",attrs:{"id":"siret","disabled":!_vm.isEditing,"rules":[_vm.rules.name],"outlined":"","type":"text"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"input__label"},[_vm._v("Nom de votre activité "),_c('span',{staticClass:"required"},[_vm._v("*")])])]},proxy:true}],null,false,2692228454),model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('v-text-field',{staticClass:"position-right",attrs:{"id":"siret","disabled":!_vm.isEditing,"rules":[_vm.rules.siret],"outlined":"","type":"text"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"input__label"},[_vm._v("SIRET "),_c('span',{staticClass:"required"},[_vm._v("*")])])]},proxy:true}],null,false,2201112533),model:{value:(_vm.formData.siret),callback:function ($$v) {_vm.$set(_vm.formData, "siret", $$v)},expression:"formData.siret"}})],1),_c('div',{staticClass:"content-x1"},[_c('v-combobox',{staticClass:"position-left",attrs:{"id":"street","value":_vm.formData.activityAddress.street,"items":_vm.searchItemsStreetList,"disabled":!_vm.isEditing,"rules":[_vm.rules.street],"outlined":""},on:{"keyup":function (event) {
                _vm.updateAddress(event, 'street');
                _vm.searchAddress(event.target.value, 'street', 'activity');
              },"input":function (event) { return _vm.updateAddress(event, 'street'); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"input__label"},[_vm._v("Adresse de l'activité "),_c('span',{staticClass:"required"},[_vm._v("*")])])]},proxy:true}],null,false,812093996)})],1),_c('div',{staticClass:"content-x2"},[_c('v-combobox',{staticClass:"position-left",attrs:{"id":"city","value":_vm.formData.activityAddress.city,"items":_vm.searchItemsCityList,"disabled":!_vm.isEditing,"rules":[_vm.rules.city],"outlined":""},on:{"keyup":function (event) {
                _vm.updateAddress(event, 'city');
                _vm.searchAddress(event.target.value, 'city', 'activity');
              },"input":function (event) { return _vm.updateAddress(event, 'city'); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"input__label"},[_vm._v("Ville "),_c('span',{staticClass:"required"},[_vm._v("*")])])]},proxy:true}],null,false,2482355414)}),_c('v-combobox',{staticClass:"position-right",attrs:{"id":"zip","value":_vm.formData.activityAddress.zip,"items":_vm.searchItemsZipList,"disabled":!_vm.isEditing,"rules":[_vm.rules.zip],"outlined":""},on:{"keyup":function (event) {
                _vm.updateAddress(event, 'zip');
                _vm.searchAddress(event.target.value, 'zip', 'activity');
              },"input":function (event) { return _vm.updateAddress(event, 'zip'); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"input__label"},[_vm._v("Code postal "),_c('span',{staticClass:"required"},[_vm._v("*")])])]},proxy:true}],null,false,2451280180)})],1),(_vm.actions !== 'create')?_c('div',[_c('p',[_vm._v("Avez-vous adhéré à un centre de gestion agréé (CGA/AGA) ?")]),_c('div',{staticClass:"mb-8"},[_c('v-btn',{staticClass:"hasCga-btn",class:{
                active: _vm.formData.hasCga,
              },attrs:{"value":_vm.formData.hasCga,"depressed":"","disabled":!_vm.isEditing},on:{"click":function($event){return _vm.toggleCgaBtn(true)}}},[_vm._v("Oui")]),_c('v-btn',{staticClass:"hasCga-btn",class:{ active: !_vm.formData.hasCga },attrs:{"value":_vm.formData.hasCga,"depressed":"","disabled":!_vm.isEditing},on:{"click":function($event){return _vm.toggleCgaBtn(false)}}},[_vm._v("Non")])],1)]):_vm._e(),(_vm.formData.hasCga)?_c('div',{staticClass:"section-infos"},[_c('div',{staticClass:"content-x2"},[_c('v-text-field',{staticClass:"position-left",attrs:{"id":"cga-name","disabled":!_vm.isEditing,"rules":[_vm.rules.cgaName],"outlined":"","type":"text"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"input__label"},[_vm._v("Nom du CGA "),_c('span',{staticClass:"required"},[_vm._v("*")])])]},proxy:true}],null,false,3722945044),model:{value:(_vm.formData.cga.name),callback:function ($$v) {_vm.$set(_vm.formData.cga, "name", $$v)},expression:"formData.cga.name"}}),_c('v-text-field',{staticClass:"position-right",attrs:{"id":"cga-agreement-number","disabled":!_vm.isEditing,"rules":[_vm.rules.cgaAgreementNumber],"outlined":"","type":"text"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"input__label"},[_vm._v("Numéro d'agrément du CGA "),_c('span',{staticClass:"required"},[_vm._v("*")])])]},proxy:true}],null,false,3365189334),model:{value:(_vm.formData.cga.agreementNumber),callback:function ($$v) {_vm.$set(_vm.formData.cga, "agreementNumber", $$v)},expression:"formData.cga.agreementNumber"}})],1),_c('div',{staticClass:"content-x1"},[_c('v-combobox',{staticClass:"position-left",attrs:{"id":"cga-street","value":_vm.formData.cga.address.street,"items":_vm.searchItemsCgaStreetList,"disabled":!_vm.isEditing,"rules":[_vm.rules.cgaStreet],"outlined":""},on:{"keyup":function (event) {
                  _vm.updateCgaAddress(event, 'street');
                  _vm.searchAddress(event.target.value, 'street', 'cga');
                },"input":function (event) { return _vm.updateCgaAddress(event, 'street'); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"input__label"},[_vm._v("Adresse du CGA "),_c('span',{staticClass:"required"},[_vm._v("*")])])]},proxy:true}],null,false,3915179791)})],1),_c('div',{staticClass:"content-x2"},[_c('v-combobox',{staticClass:"position-left",attrs:{"id":"cga-city","value":_vm.formData.cga.address.city,"items":_vm.searchItemsCgaCityList,"disabled":!_vm.isEditing,"rules":[_vm.rules.cgaCity],"outlined":""},on:{"keyup":function (event) {
                  _vm.updateCgaAddress(event, 'city');
                  _vm.searchAddress(event.target.value, 'city', 'cga');
                },"input":function (event) { return _vm.updateCgaAddress(event, 'city'); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"input__label"},[_vm._v("Ville "),_c('span',{staticClass:"required"},[_vm._v("*")])])]},proxy:true}],null,false,2482355414)}),_c('v-combobox',{staticClass:"position-right",attrs:{"id":"cga-zip","value":_vm.formData.cga.address.zip,"items":_vm.searchItemsCgaZipList,"disabled":!_vm.isEditing,"rules":[_vm.rules.cgaZip],"outlined":""},on:{"keyup":function (event) {
                  _vm.updateCgaAddress(event, 'zip');
                  _vm.searchAddress(event.target.value, 'zip', 'cga');
                },"input":function (event) { return _vm.updateCgaAddress(event, 'zip'); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"input__label"},[_vm._v("Code postal "),_c('span',{staticClass:"required"},[_vm._v("*")])])]},proxy:true}],null,false,2451280180)})],1)]):_vm._e()]),(_vm.actions !== 'create')?_c('v-alert',{attrs:{"type":"info","icon":"mdi-information","outlined":"","text":""}},[_vm._v(" Bon à savoir : si vous faites appel aux services d'un expert-comptable, l'adhésion à un centre de gestion agréé vous permet de bénéficier d'"),_c('strong',[_vm._v("une réduction d'impôt sur le revenu de votre activité LMNP")]),_vm._v(" correspondant à 2/3 de vos frais de comptabilité en LMNP. ")]):_vm._e(),_c('div',{staticClass:"section-action"},[(_vm.isEditing && _vm.currentActivity.id)?_c('v-btn',{staticClass:"position-left",attrs:{"color":"primary","depressed":"","outlined":""},on:{"click":_vm.cancelUpdate}},[_vm._v(" Annuler ")]):_vm._e(),(_vm.isEditing)?_c('v-btn',{staticClass:"position-right",attrs:{"id":"update-validate","loading":_vm.isLoading,"color":"primary","depressed":"","type":"submit"},on:{"":function($event){}}},[_vm._v(" Valider ")]):_vm._e()],1)],1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }