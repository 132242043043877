
















































































































































import { coreStore, productsStore, activitiesStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ActivitiesModel, ProductsModel } from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  ref,
  Ref,
} from "@vue/composition-api";
import SCISearch from "@/components/sign/signup/steps/SCISearch.vue";
import { debounce, onlyNumber } from "@/utils";

export default defineComponent({
  name: "ActivityInfos",
  components: {
    SCISearch,
  },
  props: {
    loading: { type: Boolean, default: false },
    productId: { type: String },
    isEditable: { type: Boolean, default: false },
    actions: { type: String as PropType<"create" | "update"> },
  },

  setup(props, context) {
    /**
     * ActivityData
     */
    const isLoading: Ref<boolean> = ref(false);
    const isEditing: Ref<boolean> = ref(props.isEditable);

    const currentActivity: ComputedRef<
      | ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.COMPANY>
      | undefined
    > = computed(
      () =>
        activitiesStore.currentActivity as ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.COMPANY>
    );

    const baseActivity = computed(() => {
      return {
        type:
          currentActivity.value?.type ?? ActivitiesModel.ActivityTypes.COMPANY,
        name: currentActivity.value?.name ?? "",
        siret: currentActivity.value?.siret ?? "",
        address: {
          street: currentActivity.value?.address?.street ?? "",
          city: currentActivity.value?.address?.city ?? "",
          zip: currentActivity.value?.address?.zip ?? "",
        },
      };
    });

    const formData = ref({
      ...baseActivity.value,
    });

    const rules = ref({
      name: () =>
        !!formData.value?.name || "Renseignez le nom de votre société",
      siret: () =>
        !formData.value?.siret ||
        (!!formData.value?.siret &&
          onlyNumber(formData.value.siret) &&
          formData.value?.siret.length === 14) ||
        "Le format de votre SIRET est incorrect",
    });

    const productId = computed(() => productsStore.currentId);

    async function updateActivity() {
      isLoading.value = true;

      // Validate
      let isValidRules = true;
      for (const rule in rules.value) {
        const isValidRule = rules.value[rule]();
        if (isValidRule !== true) {
          isValidRules = false;
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message: isValidRule,
          });
        }
      }

      // Process
      if (isValidRules && currentActivity.value?.id) {
        try {
          const activityToSave = {
            id: currentActivity.value.id,
            ...formData.value,
          };
          context.emit("validate", true);
          if (currentActivity.value?.id) {
            await productsStore.updateProduct({
              id: productId.value,
              name: activityToSave.name,
            });
            await activitiesStore.updateActivity(activityToSave);
          } else {
            await productsStore.createProduct({
              type: ProductsModel.ProductTypes.SCI,
              name: activityToSave.name,
              activity: activityToSave,
              accountingPeriods: [],
            });
          }
          coreStore.displayFeedback({
            message:
              "La mise à jour de votre activité a bien été prise en compte",
          });
          isEditing.value = false;
        } catch (err) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "Une erreur est survenue lors de la mise à jour de votre SCI",
          });
          isLoading.value = false;
        } finally {
          isLoading.value = false;
        }
      }
      isLoading.value = false;
    }

    function cancelUpdate() {
      formData.value = { ...baseActivity.value };
      isEditing.value = false;
    }

    const searchItemsStreetList = ref([]);
    const searchItemsCityList = ref([]);
    const searchItemsZipList = ref([]);

    const searchAddress = debounce((value: string, type: string) => {
      if (value.length > 2) {
        if (type === "street") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=housenumber`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                if (
                  feature.properties &&
                  feature.properties.name &&
                  feature.properties.postcode &&
                  feature.properties.city
                )
                  return {
                    text: `${feature.properties.name} ${feature.properties.postcode} - ${feature.properties.city}`,
                    value: feature.properties,
                  };
              });
              searchItemsStreetList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        } else if (type === "city") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                return {
                  text: `${feature.properties.city} - ${feature.properties.postcode}`,
                  value: feature.properties,
                };
              });
              searchItemsCityList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        } else if (type === "zip") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                return {
                  text: `${feature.properties.postcode} - ${feature.properties.city}`,
                  value: feature.properties,
                };
              });
              searchItemsZipList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        }
      }
    }, 500);

    const updateAddress = (event, field) => {
      if (formData.value.address) {
        if (event.type === "keyup")
          formData.value.address[field] = event.target.value;
        else {
          if (field === "street") {
            formData.value.address.street = event.value.name;
            formData.value.address.city = event.value.city;
            formData.value.address.zip = event.value.postcode;
          } else if (field === "city") {
            formData.value.address.city = event.value.city;
            formData.value.address.zip = event.value.postcode;
          } else if (field === "zip") {
            formData.value.address.city = event.value.city;
            formData.value.address.zip = event.value.postcode;
          }
        }
      }
    };

    return {
      currentActivity,
      isLoading,
      isEditing,
      onlyNumber,
      rules,
      cancelUpdate,
      updateActivity,
      searchAddress,
      updateAddress,
      formData,
      searchItemsStreetList,
      searchItemsCityList,
      searchItemsZipList,
    };
  },
});
