







































































































































































































































































































































import { VForm } from "@/models";
import { productsStore, coreStore, tasksStore, activitiesStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { debounce, onlyNumber } from "@/utils";
import { ActivitiesModel } from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  ref,
  Ref,
} from "@vue/composition-api";
import Vue from "vue";

export default defineComponent({
  name: "MyBusinessGeneralInfo",
  props: {
    loading: { type: Boolean, default: false },
    productId: { type: String },
    actions: { type: String as PropType<"create" | "update"> },
  },

  setup(props, context) {
    const notChecked: ComputedRef<Boolean> = computed(() => {
      return true;
    });
    /**
     * ProductData
     */
    const isEditing: Ref<boolean> = ref(false);
    const isLoading: Ref<boolean> = ref(props.loading);

    const currentActivity: ComputedRef<
      | ActivitiesModel.Activity<
          ActivitiesModel.ActivityTypes.OPERATOR,
          ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON
        >
      | undefined
    > = computed(
      () =>
        activitiesStore.currentActivity as
          | ActivitiesModel.Activity<
              ActivitiesModel.ActivityTypes.OPERATOR,
              ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON
            >
          | undefined
    );

    const formData = ref({
      name: JSON.parse(JSON.stringify(productsStore.currentProduct?.name)),
      ...JSON.parse(JSON.stringify(productsStore.currentProduct?.activity)),
    });

    const rules = computed(() => ({
      cgaZip: () =>
        formData.value?.hasCga
          ? (!!formData.value?.cga?.address?.zip &&
              onlyNumber(formData.value.cga.address.zip)) ||
            "Le format du code postal de votre CGA est incorrect"
          : true || "",
      cgaCity: () =>
        formData.value?.hasCga
          ? !!formData.value.cga?.address?.city ||
            "Renseignez la ville associée à votre CGA"
          : true || "",
      cgaStreet: () =>
        formData.value?.hasCga
          ? !!formData.value.cga?.address?.street ||
            "Renseignez la rue associée à votre CGA"
          : true || "",
      cgaAgreementNumber: () =>
        formData.value?.hasCga
          ? (!!formData.value.cga?.agreementNumber &&
              onlyNumber(formData.value.cga.agreementNumber) &&
              formData.value.cga.agreementNumber.length === 6) ||
            "Le format du numéro d'agrément de votre CGA est incorrect"
          : true || "",
      cgaName: () =>
        formData.value?.hasCga
          ? !!formData.value?.cga?.name || "Renseignez le nom de votre CGA"
          : true || "",

      name: () =>
        !!formData.value?.name || "Renseignez le nom de votre activité",
      zip: () =>
        (!!formData.value?.activityAddress?.zip &&
          onlyNumber(formData.value.activityAddress.zip)) ||
        "Le format du code postal de votre activité est incorrect",
      city: () =>
        !!formData.value?.activityAddress?.city ||
        "Renseignez la ville associée à votre activité",
      street: () =>
        !!formData.value?.activityAddress?.street ||
        "Renseignez la rue associée à votre activité",
      siret: () =>
        (!!formData.value?.siret &&
          onlyNumber(formData.value.siret) &&
          formData.value?.siret.length === 14) ||
        "Le format de votre SIRET est incorrect",
    }));

    const searchItemsStreetList = ref([]);
    const searchItemsCityList = ref([]);
    const searchItemsZipList = ref([]);

    const searchItemsCgaStreetList = ref([]);
    const searchItemsCgaCityList = ref([]);
    const searchItemsCgaZipList = ref([]);

    const searchAddress = debounce(
      (value: string, type: string, addressSubject: "activity" | "cga") => {
        if (value.length > 2) {
          if (type === "street") {
            fetch(
              `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=housenumber`
            )
              .then((response) => response.json())
              .then((data) => {
                const mappedData = data.features.map((feature) => {
                  if (
                    feature.properties &&
                    feature.properties.name &&
                    feature.properties.postcode &&
                    feature.properties.city
                  )
                    return {
                      text: `${feature.properties.name} ${feature.properties.postcode} - ${feature.properties.city}`,
                      value: feature.properties,
                    };
                });
                if (addressSubject === "activity") {
                  searchItemsStreetList.value = mappedData.filter(
                    (mappedData) => !!mappedData
                  );
                } else if (addressSubject === "cga") {
                  searchItemsCgaStreetList.value = mappedData.filter(
                    (mappedData) => !!mappedData
                  );
                }
              });
          } else if (type === "city") {
            fetch(
              `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
            )
              .then((response) => response.json())
              .then((data) => {
                const mappedData = data.features.map((feature) => {
                  return {
                    text: `${feature.properties.city} - ${feature.properties.postcode}`,
                    value: feature.properties,
                  };
                });
                if (addressSubject === "activity") {
                  searchItemsCityList.value = mappedData.filter(
                    (mappedData) => !!mappedData
                  );
                } else if (addressSubject === "cga") {
                  searchItemsCgaCityList.value = mappedData.filter(
                    (mappedData) => !!mappedData
                  );
                }
              });
          } else if (type === "zip") {
            fetch(
              `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
            )
              .then((response) => response.json())
              .then((data) => {
                const mappedData = data.features.map((feature) => {
                  return {
                    text: `${feature.properties.postcode} - ${feature.properties.city}`,
                    value: feature.properties,
                  };
                });
                if (addressSubject === "activity") {
                  searchItemsZipList.value = mappedData.filter(
                    (mappedData) => !!mappedData
                  );
                } else if (addressSubject === "cga") {
                  searchItemsCgaZipList.value = mappedData.filter(
                    (mappedData) => !!mappedData
                  );
                }
              });
          }
        }
      },
      500
    );

    const updateAddress = (event, field) => {
      if (formData.value.activityAddress) {
        if (event.type === "keyup")
          formData.value.activityAddress[field] = event.target.value;
        else {
          if (field === "street") {
            formData.value.activityAddress.street = event.value.name;
            formData.value.activityAddress.city = event.value.city;
            formData.value.activityAddress.zip = event.value.postcode;
          } else if (field === "city") {
            formData.value.activityAddress.city = event.value.city;
            formData.value.activityAddress.zip = event.value.postcode;
          } else if (field === "zip") {
            formData.value.activityAddress.city = event.value.city;
            formData.value.activityAddress.zip = event.value.postcode;
          }
        }
      }
    };

    const updateCgaAddress = (event, field) => {
      if (formData.value.cga && formData.value.cga.address) {
        if (event.type === "keyup")
          Vue.set(formData.value.cga.address, field, event.target.value);
        else {
          if (field === "street") {
            console.log(formData.value.cga);
            Vue.set(formData.value, "cga", {
              ...JSON.parse(JSON.stringify(formData.value.cga)),
              address: {
                street: event.value.name,
                city: event.value.city,
                zip: event.value.postcode,
                ...JSON.parse(JSON.stringify(formData.value.cga)),
              },
            });
          } else if (field === "city") {
            Vue.set(formData.value, "cga", {
              ...JSON.parse(JSON.stringify(formData.value.cga)),
              address: {
                city: event.value.city,
                zip: event.value.postcode,
                ...JSON.parse(JSON.stringify(formData.value.cga)),
              },
            });
          } else if (field === "zip") {
            Vue.set(formData.value, "cga", {
              ...JSON.parse(JSON.stringify(formData.value.cga)),
              address: {
                city: event.value.city,
                zip: event.value.postcode,
                ...JSON.parse(JSON.stringify(formData.value.cga)),
              },
            });
          }
        }
      }
    };

    /**
     * Actions
     */
    async function updateProduct() {
      isLoading.value = true;

      // Validate
      let isValidRules = true;
      for (const rule in rules.value) {
        const isValidRule = rules.value[rule]();
        if (isValidRule !== true) {
          isValidRules = false;
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message: isValidRule,
          });
        }
      }

      if (formData.value) {
        if (!formData.value.hasCga && !formData.value.cga) {
          delete formData.value.cga;
        }
      }

      // Process
      if (
        isValidRules &&
        formData.value &&
        (context.refs.form as VForm).validate()
      ) {
        try {
          const { name, cga, ...activityUpdateRest } = formData.value;
          await productsStore.updateProduct({
            id: productsStore.currentId,
            name: name,
          });
          await activitiesStore.updateActivity({
            id: currentActivity.value?.id,
            ...activityUpdateRest,
            cga: activityUpdateRest.hasCga ? cga : undefined,
          });
          await tasksStore.fetchTaskGroups({});
          coreStore.displayFeedback({
            message: "La mise à jour de votre LMNP a bien été prise en compte",
          });
          context.emit("validate", true);
          isEditing.value = false;
        } catch (err) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "Une erreur est survenue lors de la mise à jour de votre LMNP",
          });

          isLoading.value = false;
        }
      }

      isLoading.value = false;
    }

    function cancelUpdate() {
      formData.value = {
        name: productsStore.currentProduct?.name,
        ...productsStore.currentProduct?.activity,
      };
      isEditing.value = false;
    }

    // Flips CGA button according to user action before cancel or confirm action
    const toggleCgaBtn = (boolean: boolean) => {
      if (boolean === true) {
        if (formData.value) {
          formData.value.cga = {
            name: "",
            agreementNumber: "",
            address: { street: "", city: "", zip: "" },
          };
        }
      }

      formData.value.hasCga = boolean;
    };

    return {
      currentActivity,
      formData,
      isLoading,
      isEditing,
      onlyNumber,
      rules,
      cancelUpdate,
      updateProduct,
      notChecked,
      toggleCgaBtn,
      searchItemsStreetList,
      searchItemsCityList,
      searchItemsZipList,
      searchAddress,
      searchItemsCgaStreetList,
      searchItemsCgaCityList,
      searchItemsCgaZipList,
      updateAddress,
      updateCgaAddress,
    };
  },
});
